import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
//Components
import Layout from "../components/Layout/Layout"
import PageHero from "../components/PageHero/PageHero"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import PageSidebar from "../components/PageSidebar/PageSideBar"
import TransformOembedToIframe from "../utils/TransformOembedToIframe"

const Img = styled.div`
  div.gatsby-image-wrapper {
    max-height: 250px !important;
  }
`

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px 0px;

  @media (max-width: 992px) {
    padding: 20px;
  }
`

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

const PageContent = styled.article`
  margin-top: 5%;
  width: 100%;

  h1 {
    font-family: "Gobold", sans-serif;
    line-height: 60px;
    font-weight: 100;
    letter-spacing: 4.5px;
    text-align: center;
  }

  a {
    color: #fff;
    &:hover {
      color: #000;
    }
  }

  iframe {
    width: 100%;
  }

  figcaption {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;

    @media (max-width: 768px) {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    @media (min-width: 768px) {
      text-align: justify;
      direction: ltr;
      word-break: break-word;
    }
  }

  img {
    border: solid 10px #ffffff;
    transition: 5s all ease-in-out;
    cursor: progress;

    :hover {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  .content {
    padding: 0.5rem;
  }

  @media (min-width: 450px) {
    .content {
      padding: 1rem;
    }
  }

  @media (min-width: 768px) {
    .content {
      padding: 1.5rem;
    }
  }

  @media (min-width: 992px) {
    margin-top: 3%;
    h1 {
      letter-spacing: 20px;
      font-size: 5em;
    }
    .content {
      padding: 2rem;
    }
  }
`

const PageTemplate = ({ data }) => (
  <Layout>
    <Img>
      {data.wpPage.featuredImage ? (
        <PageHero
          img={
            data.wpPage.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
        />
      ) : null}
    </Img>
    <Wrapper>
      <BreadCrumb parent={data.wpPage.wpParent && data.wpPage.wpParent.node} />

      <ContentWrapper>
        <PageContent>
          <h1 dangerouslySetInnerHTML={{ __html: data.wpPage.title }} />

          <div className="content"
            dangerouslySetInnerHTML={{
              __html: TransformOembedToIframe(data.wpPage.content),
            }}
          />
        </PageContent>
      </ContentWrapper>
    </Wrapper>
  </Layout>
)

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      status
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
            }
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            uri
            title
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            id
            uri
            title
            wpChildren {
              nodes {
                ... on WpPage {
                  id
                  title
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`
